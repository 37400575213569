import React from "react"
import { Link } from "react-scroll"
import Sticky from "react-stickynode"

const Pages = ({ data, sidebar }) => {
  return (
    <div className="pages-wrap bg-white p-4 border-box flex flex-row">
      <div className="w-3/12">
        <Sticky top={50}>
          <div className="controls bg-yellow rounded-xl p-4">
            {sidebar.distinct.map(postion => {
              let anchor = sidebar.nodes.filter(
                elem => elem.meta.sectionPosition === postion
              )[0]
              return (
                <Link
                  smooth={true}
                  offset={50}
                  duration={500}
                  activeClass="anchor-active"
                  to={`${anchor.meta.sectionName.split(" ").join("_")}`}
                  className="block mb-4 text-2xl p-4 hover:bg-purple hover:text-white rounded-lg"
                >
                  {parseInt(postion) + 1}. {anchor.meta.sectionName}
                </Link>
              )
            })}
          </div>
        </Sticky>
      </div>
      <div className="content w-9/12">
        {data.distinct.map(position => {
          let slide = data.nodes.filter(
            elem => elem.meta.position === position
          )[0]
          return (
            <div
              className="page-wrap"
              id={slide.meta.sectionName.split(" ").join("_")}
            >
              <img src={slide.url} alt={slide.meta.name} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Pages
