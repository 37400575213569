import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Pages from "../components/pages"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <Hero data={data.heroBanner.nodes}/>
    <Pages data={data.pages} sidebar={data.sidebar}/>
    {/* <HeroBanner data={data.heroBanner.nodes} />
    <Grid4x1 data={data.grid4x1} />
    <Offer1 data={data.offer1} />
    <Offer2 data={data.offer2} />
    <FeaturedProducts data={data.featured_product}/> */}
  </Layout>
)

export const query = graphql`
  {
    heroBanner: allMasonGenericCreative(
      filter: {meta: {type: {eq: "banner"}}}
      sort: {fields: updated_time, order: ASC}
      limit: 1
    ) {
      nodes {
        url
        meta {
          format
          position
          sectionName
          sectionPosition
          type
        }
      }
    }
    pages: allMasonGenericCreative(
      filter: {meta: {type: {eq: "page"}}}
      sort: {fields: updated_time, order: ASC}
    ) {
      nodes {
        url
        meta {
          format
          position
          sectionName
          sectionPosition
          type
        }
      }
      distinct(field: meta___position)
    }
    sidebar: allMasonGenericCreative(
      filter: {meta: {type: {eq: "page"}}}
      sort: {fields: updated_time, order: ASC}
    ) {
      nodes {
        url
        meta {
          format
          position
          sectionName
          sectionPosition
          type
        }
      }
      distinct(field: meta___sectionPosition)
    }
  }
`

export default IndexPage
