import React from "react"

const Hero = ({ data }) => {
  return (
    <div className="w-full">
      {data.map(banner => (
        <img src={banner.url} alt="hero image" />
      ))}
    </div>
  )
}

export default Hero
